import TaskService from '@/services/resource/task.service.js';

const state = {
    tasks: null,
    service: null,
};

const getters = {
    tasks: s => s.tasks,
    service: s => s.service,
};

const mutations = {
    setTasksData(s, tasksData) {
        s.tasks = tasksData;
    },
    setService(s, service) {
        s.service = service;
    },
};

const actions = {
    init(context) {
        context.commit('setService', new TaskService());
    },
    setTasksData({ commit }, tasks) {
        commit('setTasksData', tasks);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};

import ResourceService from '@/services/resource.service';
import axios from 'axios';

export default class UsersService extends ResourceService {
    async getAll(config = {}) {
        return await axios.post('api/v1/users/list', config);
    }

    getItemRequestUri(id) {
        return `api/v1/users/${id}`;
    }

    getItem(id) {
        return axios.get(this.getItemRequestUri(id));
    }

    save(data, isNew = false) {
        return axios.post(`api/v1/users`, data);
    }

    update(data, id) {
        return axios.put(`api/v1/users/${id}`, data);
    }

    deleteItem(id) {
        return axios.post('users/remove', { id });
    }

    getOptionLabelKey() {
        return 'full_name';
    }

    getWithFilters(filters, config = {}) {
        return axios.post('api/v1/users/list', filters, config);
    }

    getById(id) {
        return axios.get(`api/v1/users/${id}`);
    }
}

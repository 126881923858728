import ResourceService from '@/services/resource.service';
import axios from 'axios';
import { serialize } from '@/utils/url';

export default class RoleService extends ResourceService {
    constructor(params = {}) {
        super();
        this.params = params;
    }

    getItemRequestUri(id) {
        return `roles/show?id=${id}`;
    }

    getItem(id) {
        return axios.get(this.getItemRequestUri(id));
    }

    getAll(params) {
        return axios.get('api/v1/roles?' + serialize(params));
    }
}
